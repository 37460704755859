/* CSS */
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-montserrat"
import "typeface-merriweather"

/* JS */
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

// custom CSS styles
import "./src/css/style.css"

import React from 'react'

export const wrapRootElement = ({ element }) => (
    <>{element}</>
)